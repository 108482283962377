export class User {
  constructor(
    // Google
    public id: string = null,
    public verified_email: boolean = null,
    public link: string = null,
    public local: string = null,
    public gender: string = null,

    // Ilexoidc
    public sub: string = null,
    public preferred_username: string = null,
    public groups: string = null,

    // Common
    public email: string,
    public name: string,
    public given_name: string,
    public family_name: string,
    public picture: string
  ) {}

  static checkUserTrg(trigramme: string): boolean {
    if (trigramme) {
      switch (trigramme) {
        case "ABD":
        case "ABN":
        case "ACE":
        case "ADA":
        case "AGA":
        case "AGE":
        case "AJA":
        case "ALE":
        case "AMG":
        case "ANC":
        case "APD":
        case "ARE":
        case "BAN":
        case "BDU":
        case "BGH":
        case "BLE":
        case "BMI":
        case "BMR":
        case "BTO":
        case "CCS":
        case "CLU":
        case "CMG":
        case "CNI":
        case "DSA":
        case "DVA":
        case "DWN":
        case "ETS":
        case "FCN":
        case "FDI":
        case "FGEN":
        case "FLN":
        case "FTI":
        case "GCY":
        case "GGP":
        case "GMT":
        case "HYA":
        case "IPL":
        case "JBC":
        case "JBU":
        case "JFI":
        case "JPI":
        case "LEL":
        case "LKU":
        case "LLB":
        case "LLS":
        case "LPN":
        case "LVN":
        case "LWE":
        case "MBR":
        case "MBV":
        case "MCT":
        case "MDH":
        case "MDO":
        case "MEDEX":
        case "MHU":
        case "MJE":
        case "MLE":
        case "MLL":
        case "MLO":
        case "MMR":
        case "MPI":
        case "MRA":
        case "MRO":
        case "MSR":
        case "NUZ":
        case "OLE":
        case "PDE":
        case "PLU":
        case "EGS":
        case "PRT04":
        case "PRT06":
        case "PRT07":
        case "PRT15":
        case "PRT19":
        case "PRT22":
        case "PRT25":
        case "PRT28":
        case "PRT30":
        case "PRT31":
        case "PRT33":
        case "PRT34":
        case "PRT37":
        case "RLI":
        case "SCR":
        case "SFL":
        case "SJE":
        case "SKA":
        case "SLH":
        case "SLI":
        case "STG11":
        case "STG12":
        case "STG18":
        case "STG45":
        case "STG50":
        case "STG53":
        case "STG90":
        case "STI":
        case "TFE":
        case "TLU":
        case "VGM":
        case "VLB":
        case "VLC":
        case "VPA":
        case "VSA":
        case "WRO":
        case "WSA":
        case "XLE":
        case "YCH":
        case "CSAI":
        case "ASIG":
        case "MHUE":
        case "BTEA":
        case "VBAU":
        case "YCAN":
        case "MCIL":
        case "RBOU":
        case "LMAI":
        case "MMIA":
        case "SMER":
        case "CROB":
        case "LSEL":
        case "STIL":
        case "MVER":
        case "NMR":
        case "VLE":
        case "GTR":
        case "PGA":
        case "FHER":
        case "BGE":
        case "LCI":
        case "ANI":
        case "SJA":
        case "SEME":
        case "STG54":
        case "STG48":
        case "STG42":
        case "VTH":
        case "JCH":
        case "MIQ":
        case "MCN":
        case "JISM":
        case "RTC":
        case "JNT":
        case "CMH":
        case "AFAN":
        case "MLAC":
        case "CLD":
        case "P0015":
        case "NBO":
        case "PRT61":
        case "P0019":
        case "P0029":
        case "RDJ":
        case "ACH":
        case "JLI":
        case "JJEA":
        case "BOU":
        case "JBC":
        case "PPE":
        case "VFO":
        case "ATA":
        case "ADR":
        case "P0031":
        case "SWT":
        case "QGU":
        case "BRY": {
          // user authorized
          return true;
        }
        default: {
          return false;
        }
      }
    }
    return false;
  }
}

export interface JsonUserParam {
  area: string[];
  category: string[];
}

export class UserParam {
  public area: string[];
  public category: string[];

  constructor(jsonUserParam?: JsonUserParam) {
    if (jsonUserParam && jsonUserParam.area) {
      this.area = jsonUserParam.area;
    }

    if (jsonUserParam && jsonUserParam.category) {
      this.category = jsonUserParam.category;
    }
  }
}
